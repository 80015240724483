import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Layout } from '../../components';
import {
    useGetClockInStatusQuery,
    useGetDefaultEquipmentQuery,
    useGetWorklinesQuery
} from '../../services/api';

const PageRouteWrapper = ({ onSignOut }) => {
    const navigate = useNavigate();
    const { user, timeClock } = useSelector((state) => state.employee);

    const [hasNavigated, setHasNavigated] = useState(false);

    const {
        isSuccess: isClockInStatusSuccess,
        isLoading: isClockInStatusLoading,
    } = useGetClockInStatusQuery(user.id, { skip: !user.id });
    const {
        isSuccess: isEquipmentStatusSuccess,
        isLoading: isEquipmentStatusLoading,
    } = useGetDefaultEquipmentQuery(user.id, { skip: !user.id });

    const {
        data: workLines = [],
        isLoading: isWorkLinesLoading,
        isSuccess: isWorkLinesSuccess,
    } = useGetWorklinesQuery(user.id, {
        skip: !user.id || !timeClock.isActive,
    });

    useEffect(() => {
        if (!hasNavigated && isClockInStatusSuccess && isEquipmentStatusSuccess && !timeClock.isLoading && !isWorkLinesLoading) {
            // User is clocked in
            if (timeClock.isActive) {
                const hasActiveWorkLines = Array.isArray(workLines) && workLines.length > 0 && workLines.some((line) => line?.isFinished === false);

                if (hasActiveWorkLines) navigate('/work'); // Navigate to /work if active work lines 
                else navigate('/newline'); // Navigate to /newline if no active work lines exist
            } else {
                console.log('Time clock is not active');
                navigate('/timeclock');
            }
            setHasNavigated(true);
        }
    }, [
        isClockInStatusSuccess,
        isEquipmentStatusSuccess,
        hasNavigated,
        timeClock.isActive,
        timeClock.isLoading,
        isWorkLinesLoading,
        isWorkLinesSuccess,
        workLines,
        navigate,
    ]);

    const isLoading =
        !hasNavigated ||
        isEquipmentStatusLoading ||
        isClockInStatusLoading ||
        isWorkLinesLoading;

    if (isLoading) return null;

    return <Layout onSignOut={onSignOut} />;
};

export default PageRouteWrapper;
