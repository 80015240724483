import dayjs from 'dayjs';
import {
	WAITING_APPROVAL,
	APPROVED,
	REJECTED,
	SICK_LEAVE,
	SICK_LEAVE_WITH_CHILD,
	VACATION,
	OTHER,
	CLOCK_IN
} from '../constants';
import i18n from 'i18next';

export const getDisplayDate = (date) => {
	const isToday = dayjs(date).isSame(dayjs(), 'day');
	const isYesterday = dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day');
	const displayDate =
			isToday ? `${i18n.t('Today')}, ${dayjs(date).format('DD MMMM')}` :
			isYesterday ? `${i18n.t('Yesterday')}, ${dayjs(date).format('DD MMMM')}` :
			dayjs(date).format('dddd, DD MMMM');
	return displayDate;
};

export const getStatusLabel = (status) => {
	switch (status) {
		case WAITING_APPROVAL:
			return i18n.t('Waiting Approval');
		case APPROVED:
			return i18n.t('Approved');
		case REJECTED:
			return i18n.t('Rejected');
		default:
			return '';
	}
};

export const getStatusColor = (status) => {
	switch (status) {
		case APPROVED:
			return 'success';
		case REJECTED:
			return 'error'
		default:
			return 'primary';
	}
};

export const getTypeLabel = (record) => {
	if (!record?.type) return '';

	switch (record.type) {
		case SICK_LEAVE:
			return i18n.t('Sick leave');
		case SICK_LEAVE_WITH_CHILD:
			return i18n.t('Sick leave with child');
		case VACATION:
			return i18n.t('Vacation');
		case OTHER:
			return i18n.t('Other');
		case CLOCK_IN:
			return !record.end ? i18n.t('Clock in') : record.start && record.end ? i18n.t("Time entry") : '';
		default:
			return i18n.t('Other');
	}
};

export const getTimeRangeWithDuration = (record) => {
	let formatted = '';
	if (record?.start) {
		formatted += dayjs(record.start).format('HH:mm') + ' - ';
	}
	if (record?.end) {
		formatted += dayjs(record.end).format('HH:mm');
	}
	if (record?.durationMinutes) {
		formatted += `, ${dayjs
			.duration(record.durationMinutes, 'minutes')
			.asHours()
			.toFixed(2)} ${i18n.t('hours').toLowerCase()}`;
	}
	return formatted;
};