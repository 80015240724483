import { useTranslation } from 'react-i18next';
import {
    Container,
    Button,
    Typography,
    Paper,
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

export default function NoPrivilegesPage({ onSignOut }) {
    const { t } = useTranslation()

    return (
        <Container
            component="main"
            maxWidth="sm"
            sx={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2,
                }}
            >
                <LockOutlinedIcon sx={{ fontSize: 48, color: 'error.main', mb: 2 }} />
                <Typography component="h1" variant="h4" gutterBottom>
                    {t('noPrivileges.title')}
                </Typography>

                <Typography variant="body1" align="center" gutterBottom>
                    {t('noPrivileges.message')}
                </Typography>

                <Button
                    tabIndex={0}
                    variant="outlined"
                    color="primary"
                    onClick={onSignOut}
                    startIcon={<LogoutIcon />}
                    fullWidth
                    sx={{ mt: 6 }}
                >
                    {t('noPrivileges.signOut')}
                </Button>
            </Paper>
        </Container>
    )
}