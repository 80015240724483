import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	typography: {
		h1: {
			fontSize: '2.75rem',
			'@media (max-width:600px)': {
				fontSize: '2.25rem',
			},
		},
		h2: {
			fontSize: '2.25rem',
			'@media (max-width:600px)': {
				fontSize: '2rem',
			},
		},
		h3: {
			fontSize: '1.75rem',
			'@media (max-width:600px)': {
				fontSize: '1.5rem',
			},
		},
		h4: {
			fontSize: '1.5rem',
			'@media (max-width:600px)': {
				fontSize: '1.35rem',
			},
		},
		h5: {
			fontSize: '1.35rem',
			'@media (max-width:600px)': {
				fontSize: '1.25rem',
			},
		},
		h6: {
			fontSize: '1.25rem',
			'@media (max-width:600px)': {
				fontSize: '1.125rem',
			},
		},
		subtitle1: {
			fontSize: '1rem',
			'@media (max-width:600px)': {
				fontSize: '0.9rem',
			},
		},
		subtitle2: {
			fontSize: '0.875rem',
			'@media (max-width:600px)': {
				fontSize: '0.8rem',
			},
		},
		body1: {
			fontSize: '1rem',
		},
		body2: {
			fontSize: '0.875rem',
		},
	},
	shape: {
		borderRadius: 8,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					padding: '12px 20px',
				},
			},
		},
	},
});
