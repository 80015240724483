import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const useCurrentTime = (intervalMilliseconds) => {
	const [currentTime, setCurrentTime] = useState(dayjs());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(dayjs());
		}, intervalMilliseconds);
		return () => clearInterval(intervalId);
	}, [intervalMilliseconds]);

	return currentTime;
};

export default useCurrentTime;
