import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import i18n from '../i18n';
import {
    PageRouteWrapper,
    TimeClockPage,
    WorkPage,
    WorkDetailsPage,
    NewWorkPage,
    NoPrivilegesPage
} from '../pages';
import { theme } from '../styles/theme';
import { withAuth } from '../msal/MsalAuthProvider';
import { employeeActions } from './employeeSlice';
import { hasPrivileges } from '../utils/privilege.utils';
import "@fontsource/roboto";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/en';
import 'dayjs/locale/is';

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.tz.setDefault('Atlantic/Reykjavik');

function App(props) {
    const dispatch = useDispatch();
    const { user, hasStimpilklukka, hasVerklinur } = useSelector((state) => state.employee);
    const [isUserLoaded, setIsUserLoaded] = useState(false);

    // Set user from auth context
    useEffect(() => {
        const user = {
            id: props.auth.crmId,
            name: props.auth.crmName,
            language: props.auth.language === 910250000 ? 'is' : 'en',
            hasBirthday: props.auth.hasBirthday,
            privileges: props.auth.crmPrivileges
        };
        dispatch(employeeActions.setUser(user));

        i18n.changeLanguage(user.language);
        dayjs.locale(user.language);
        setIsUserLoaded(true);

        return () => {
            setIsUserLoaded(false);
            dispatch(employeeActions.clearUser());
        };

    }, [props.auth, dispatch]);

    if (!isUserLoaded) return null;

    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    const hasNoPrivileges = !hasPrivileges(user);

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <BrowserRouter basename={baseUrl}>
                    <Routes>
                        {hasNoPrivileges ? (
                            <>
                                <Route path="/" element={<NoPrivilegesPage onSignOut={props.onSignOut} />} />
                                <Route path="*" element={<Navigate to="/" replace /> } />
                            </>
                        ) : (
                            <Route
                                path="/"
                                element={<PageRouteWrapper onSignOut={() => props.onSignOut()} />}
                            >
                                {hasStimpilklukka && (
                                    <>
                                        <Route path="/timeclock" element={<TimeClockPage />} />
                                        {!hasVerklinur && <Route path="*" element={<Navigate to="/timeclock" replace />} />}
                                    </>
                                )}
                                {hasVerklinur && (
                                    <>
                                        <Route path="/work" element={<WorkPage />} />
                                        <Route path="/work/details/:id" element={<WorkDetailsPage />} />
                                        <Route path="/newline" element={<NewWorkPage />} />
                                        {!hasStimpilklukka && <Route path="*" element={<Navigate to="/work" replace />} />}
                                    </>
                                )}
                                {hasStimpilklukka && hasVerklinur && (
                                    <Route index element={<Navigate to="/timeclock" replace />} />
                                )}
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Route>
                        )}
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

export default withAuth(App);
