import React, { useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	TextField,
	MenuItem,
	Box,
} from '@mui/material';

import {
	SICK_LEAVE,
	SICK_LEAVE_WITH_CHILD,
	VACATION,
	OTHER,
} from '../../constants';
import { useReportAbsentMutation } from '../../services/api';
import { LoadingButton } from '../../components';

const ReportAbsentDialog = ({ open, setOpen }) => {
	const { t } = useTranslation();

	const { user } = useSelector((state) => state.employee);
	const [reportAbsent, { isLoading }] = useReportAbsentMutation();
	const [value, setValue] = useState('');

	const options = [
		{
			value: SICK_LEAVE,
			label: t('Sick leave'),
		},
		{
			value: SICK_LEAVE_WITH_CHILD,
			label: t('Sick leave with child'),
		},
		{
			value: VACATION,
			label: t('Vacation'),
		},
		{
			value: OTHER,
			label: t('Other'),
		},
	];

	const handleReportAbsent = () => {
		const timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		const option = options.find((option) => option.value === value);

		reportAbsent({
			employeeId: user.id,
			timestamp: timestamp,
			reason: option?.value,
		})
			.unwrap()
			.then(() => {
				toast.success(
					`${option.label}: ${dayjs(timestamp).format(
						'ddd DD MMM HH:mm',
					)}`,
				);
				setOpen(false);
			})
			.catch((error) => {
				toast.error(`Failed to clock in. Reason: ${error.message}`);
			});
	};

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>{t('Report Absent')}</DialogTitle>
			<DialogContent>
				<Box sx={{ py: 1 }}>
					<TextField
						label={t('Reason')}
						value={value}
						onChange={handleChange}
						autoFocus
						fullWidth
						select
					>
						{options.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="error" onClick={() => setOpen(false)}>
					{t('Cancel')}
				</Button>
				<LoadingButton
					onClick={handleReportAbsent}
					loading={isLoading}
					disabled={!value}
				>
					{t('Confirm')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
export default ReportAbsentDialog;
