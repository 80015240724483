import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    Skeleton,
} from '@mui/material';

import { useGetPayPeriodSummaryQuery } from '../../services/api';

export default function PayPeriodSummaryTable() {
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.employee);
    const { data = [], isLoading } = useGetPayPeriodSummaryQuery(user.id, {
        skip: !user.id,
    });

	if (isLoading) {
		return <Skeleton variant="rectangular" height={400} />;
	}

	return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {data.map((row) => (
                <Card key={row.id} sx={{ width: '100%' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {row.payPeriod.name}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body2" color="text.secondary">
                                    {t('payPeriodSummary.Day')}
                                </Typography>
                                <Typography variant="body1">
                                    {row.totalDayWorkHours}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body2" color="text.secondary">
                                    {t('payPeriodSummary.Overtime')}
                                </Typography>
                                <Typography variant="body1">
                                    {row.totalOvertimeHours}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body2" color="text.secondary">
                                    {`${t('payPeriodSummary.Vacation')}/${t('payPeriodSummary.Abscence') }`}
                                </Typography>
                                <Typography variant="body1">
                                    {row.totalVacationHours}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body2" color="text.secondary">
                                    {`${t('payPeriodSummary.Sick')}/${t('payPeriodSummary.SickChild')}`}
                                </Typography>
                                <Typography variant="body1">
                                    {row.totalSickLeaveHours}/{row.totalChildSickLeaveHours}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </Box>
	);
}