import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import List from '@mui/material/List';
import axios from 'axios';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function SimpleChooseDialog(props) {
	const { onClose, selectedValue, open } = props;
	const [chosenEquipment, setChosenEquipment] = useState(null);
	const [equipmentList, setEquipmentList] = useState([]);
	const [value, setValue] = useState(0.5);
	const handleClose = (event, reason) => {
		if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
			setChosenEquipment(null);
			onClose(null, 0);
		}
		setChosenEquipment(null);
		onClose(selectedValue, value);
	};

	//On click of item show how many hours chooser
	const handleListItemChosen = (value) => {
		//Show control for hours
		setChosenEquipment(value);
	};

	const handleSliderChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleButtonClick = (val) => {
		onClose(chosenEquipment, value);
	};

	const marks = [
		{
			value: 1,
			label: '1 klst.',
		},
		{
			value: 5,
			label: '5 klst.',
		},
		{
			value: 10,
			label: '10 klst.',
		},
	];

	function valuetext(value) {
		return `${value}`;
	}

	//Loading of init data
	useEffect(() => {
		getAdditionalEquipment('1234');
	}, []);

	// Get equipment list
	function getAdditionalEquipment(userid) {
		axios
			.get('/api/work/GetAvailableWorklineEquipment/' + userid)
			.then((res) => {
				setEquipmentList(res.data);
			});
	}

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			maxWidth="xs"
			fullWidth={true}
		>
			<DialogTitle>
				{chosenEquipment != null
					? 'Fjöldi tíma fyrir ' + chosenEquipment.name
					: 'Veldu aukabúnað'}
			</DialogTitle>
			{chosenEquipment == null ? (
				<List sx={{ pt: 0 }}>
					{equipmentList.map((item) => (
						<ListItem
							button
							onClick={() => handleListItemChosen(item)}
							key={item.id}
						>
							{item.image != null ? (
								<img
									alt="Icon"
									src={
										'data:image/png;base64, ' +
										(item.image != null ? item.image : '')
									}
									width={50}
									height={50}
								/>
							) : (
								''
							)}
							<ListItemText
								primary={
									item.name + ' - ' + item.accessoryTypeName
								}
							/>
						</ListItem>
					))}
				</List>
			) : (
				<Box sx={{ width: 350, mx: 'auto', p: 2 }}>
					<Slider
						defaultValue={0.5}
						step={0.5}
						marks={marks}
						min={0.5}
						max={10}
						getAriaValueText={valuetext}
						aria-label="Custom marks"
						valueLabelDisplay="auto"
						onChange={handleSliderChange}
						value={typeof value === 'number' ? value : 0}
					/>
					<Button
						variant="success"
						size="md"
						style={{ width: 170, height: 50 }}
						onClick={handleButtonClick}
					>
						{'Bæta við ' + value + ' klst.'}
					</Button>
				</Box>
			)}
		</Dialog>
	);
}
