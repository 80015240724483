import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

import { useCurrentTime } from '../../hooks';

const CurrentTime = () => {
	const currentTime = useCurrentTime(1000 * 60);
	return (
		<Typography variant="h2" sx={{ fontWeight: 500 }} gutterBottom>
			<span>{dayjs(currentTime).format('HH:mm')}</span>
		</Typography>
	);
};

export default CurrentTime;
