import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import { BottomNav } from '../../components';
import packageJson from '../../../package.json';


const Layout = ({ onSignOut }) => {
	const { user, timeClock, hasStimpilklukka, hasVerklinur } = useSelector((state) => state.employee);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const handleGoBack = () => {
		navigate(-1); // Navigate to the previous location
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}
		>
			<AppBar
				position="static"
				sx={{
					color: user.hasBirthday ? grey[900] : 'white',
					...(user.hasBirthday ? {
						backgroundImage: `url("/birthday.jpg")`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'auto'
					} : {})
				}}
			>
				<Toolbar>
					{(location.pathname === '/newline' || location.pathname.includes('/work/details')) && (
						<IconButton edge="start" color="inherit" onClick={handleGoBack} aria-label="go back">
							<ArrowBackIcon />
						</IconButton>
					)}
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
							gap: 1,
						}}
					>
						<Typography variant="h5" sx={{ fontWeight: 'medium' }}>
							Grafa & Grjót
						</Typography>
						<Typography variant="h5" sx={{ fontWeight: user.hasBirthday ? 'medium': '300' }}>
							({packageJson.version})
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
						}}
					>
						<Typography sx={{ fontWeight: user.hasBirthday ? 'bold' : '300' }}>{user.name}</Typography>
						{location.pathname === '/timeclock' &&
							!timeClock.isActive && !timeClock.isLoading && (
								<Button
									onClick={onSignOut}
									variant="contained"
									size="small"
									sx={{
										py: 0,
										px: 1,
										backgroundColor: red[500],
										'&:hover': {
											backgroundColor: red[700],
										},
									}}
								>
									{t('Sign out')}
								</Button>
							)}
					</Box>
				</Toolbar>
			</AppBar>
			<Box sx={{ flexGrow: 1, py: 4, px: 1, backgroundColor: grey[100] }}>
				<Outlet />
			</Box>
			{hasStimpilklukka && hasVerklinur  && <BottomNav />}
		</Box>
	);
};

export default Layout;
