import React, { Component } from 'react';
import { msalConfig } from './msalConfig';
import { UserAgentApplication } from 'msal';
import axios from 'axios';

//IMPORTANT : here is the cache setup for msal
//When the application is deployed this gets very aggressive, users have to exit all tabs who have the app running on and reopen to get new deployed app running.
//NOTE : Check registerServiceWorker.js for more info about deployments in n+1 visits
export const msalAuth = new UserAgentApplication({
	auth: msalConfig,
	cache: {
		cacheLocation: 'localStorage',
	},
});

//This function is adden on top of main application(App) like withAuth(App)
//Here is the login functionality.
export function withAuth(HocComponent) {
	return class extends Component {
		constructor(props) {
			super(props);

			this.state = {
				isAuthenticated: false,
				user: {},
				renewIframe: false,
				hasError: false,
				errorMessage: null,
				crmPrivileges: [],
				crmId: null,
				crmName: null,
				language: null, //810360000 = Iceland, 810360001 = Eng,
				hasBirthday: false,
			};
			this.componentWillMountHelper();
		}

		componentWillMountHelper = () => {
			const isStandaloneMode = window.navigator.standalone === true; // Is launched from IOS home screen ?

			console.log("Handling MSAL redirect callback ...")
			msalAuth.handleRedirectCallback(
				// Success
				() => {
					let userAccount = msalAuth.getAccount();
					this.getCrmData(userAccount);
					return;
				},
				// Error
				(authErr, _) => {
					console.error("Failed to handle MSAL redirect callback:", authErr);
					this.setState({
						hasError: true,
						errorMessage: authErr.errorMessage,
					});
					return;
				},
			);

			if (msalAuth.isCallback(window.location.origin) || msalAuth.isCallback(window.location.hash)) {
				this.setState({
					auth: {
						renewIframe: true,
					},
				});
				return;
			}

			let userAccount = msalAuth.getAccount();
			if (!userAccount) {
				if (isStandaloneMode) {
					console.log("Fetching token silently (Standalone mode)...")
					msalAuth.acquireTokenSilent({
						scopes: ['user.read'],
					}).then(response => {
						if (response.account) {
							this.getCrmData(response.account);
						} else {
							msalAuth.loginRedirect();
						}
					}).catch(() => {
						// force interactive login
						msalAuth.loginRedirect();
					});
				} else {
					console.log("Redirecting to login ...")
					msalAuth.loginRedirect();
				}
				return;
			} else {
				this.getCrmData(userAccount);
				return;
			}
		};

		//Get User privileges, microsoft handles organization access but this app handles access to components which we control with licence in CRM.
		//Its important that the user logging in is created as a staff and has a azureid in CRM for this login progress to work.
		getCrmData = (userAccount) => {
			console.log("Fetching user info from CRM ...")
			return axios
				.get('/api/employee/GetUserInfo/' + userAccount.idToken.oid)
				.then((res) => {
					if (res.data.length > 0) {
						//if (res.data[0].appPermissions.length > 0) {
						const privileges = res.data[0].appPermissions;
						const id = res.data[0].id;
						const name = res.data[0].name;
						const language = res.data[0].languagePreference;
						const hasBirthday = res.data[0].hasBirthDay;

						this.setState({
							crmPrivileges: privileges,
							crmId: id,
							crmName: name,
							isAuthenticated: true,
							user: userAccount,
							language,
							hasBirthday,
						});
						/*} else {
                        this.setState({
                            hasError: true,
                            errorMessage: "Employee is missing permissions."
                        });
                    }*/
					} else {
						this.setState({
							hasError: true,
							errorMessage: 'Error getting employee.',
						});
					}
				});
		};
		onSignIn = () => {
			msalAuth.loginRedirect();
		};

		onSignOut() {
			msalAuth.logout();
		}

		render = () => {
			if (this.state.renewIframe) {
				return <div>hidden renew iframe - not visible</div>;
			} else if (this.state.isAuthenticated) {
				return (
					<HocComponent
						auth={this.state}
						onSignIn={() => this.onSignIn()}
						onSignOut={() => this.onSignOut()}
						{...this.props}
					/>
				);
			} else if (this.state.hasError) {
				return <div>{this.state.errorMessage}</div>;
			} else {
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100vh',
						}}
					>
						<h2>Skrái notanda inn...</h2>
					</div>
				);
			}
		};
	};
}
