import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api } from '../services/api';
import { employeeSlice } from './employeeSlice';

export const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		employee: employeeSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);
