import {
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
} from '@mui/material';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const WorklineList = ({ worklines }) => {
	const navigate = useNavigate();
	const navigateToWorkLine = (id) => navigate(`/work/details/${id}`);

	return (
		<>
			<List sx={{ width: '100%' }}>
				{worklines.map((l) => (
					<ListItem
						alignItems="flex-start"
						divider={true}
						key={l.id}
						onClick={() => navigateToWorkLine(l.id)}
						sx={{
							background: !l.isFinished
								? l.isStarted
									? '#FFF'
									: '#FFF'
								: '#e8e8e8',
						}}
					>
						<ListItemAvatar>
							<Avatar
								alt="verklína"
								variant="rounded"
								sx={{
									width: 50,
									height: 50,
									border: 2,
									borderColor: l.isStarted
										? 'success.light'
										: l.isFinished
										? 'error.light'
										: 'info.light',
								}}
								src={
									'data:image/png;base64,' +
									(l.project && l.project.coverImage
										? l.project.coverImage
										: l.workLineType && l.workLineType.image
										? l.workLineType.image
										: '')
								}
							/>
						</ListItemAvatar>
						<Box>
							<ListItemText
								primary={l.name}
								disableTypography={true}
								sx={{
									fontSize: 20,
									color: 'primary.dark',
									fontWeight: 'medium',
								}}
							/>
							<ListItemText
								primary={
									l.location && l.location.name
										? l.location.name
										: ''
								}
								disableTypography={true}
								sx={{ fontSize: 12, color: 'primary.light' }}
							/>
							<ListItemText
								primary={parse(
									l.description != null ? l.description : '',
								)}
								secondary={
									l.isNew === 'true'
										? '** NÝTT **'
										: '' + l.isFinished === 'true'
										? 'LOKIÐ'
										: ''
								}
								disableTypography={true}
								sx={{ fontSize: 14, color: 'primary.light' }}
							/>
						</Box>
					</ListItem>
				))}
			</List>
		</>
	);
};

export default WorklineList;
