import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
	Container,
	Typography,
	Box,
	Button,
	TextField,
	Avatar,
	Skeleton,
	IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useGetWorklinesQuery } from '../../services/api';
import { WorklineList } from '../../components';
import { useCurrentTime } from '../../hooks';

const WorkPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchQuery, setSearchQuery] = useState('');
	const [everyTenSeconds, setEveryTenSeconds] = useState(0);

	const refreshTime = useCurrentTime(1000 * 60); /// 60000ms = 1 min
	const displayTime = useCurrentTime(1000 * 10); /// 10000ms = 10 sec

	const { user, timeClock } = useSelector((state) => state.employee);
	const {
		data = [],
		isLoading,
		refetch,
	} = useGetWorklinesQuery(user.id, {
		skip: !user.id || !timeClock.isActive,
	});

	useEffect(() => {
		if (user.id && timeClock.isActive) {
			refetch();
			setEveryTenSeconds(0);
		}
	}, [refreshTime, refetch, user.id, timeClock.isActive]);

	useEffect(() => {
		setEveryTenSeconds((prev) => prev + 10);
	}, [displayTime]);

	const filterData = (query, data) => {
		if (!query) {
			return data;
		} else {
			return data.filter(
				(d) =>
					d.name
						.toLowerCase()
						.toLowerCase()
						.indexOf(query.toLowerCase()) >= 0,
			);
		}
	};
	const workLinesFiltered = useMemo(() => {
		return filterData(searchQuery, data);
	}, [searchQuery, data]);

	if (!timeClock.isActive) {
		return (
			<Container maxWidth="md">
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2}}>
					<Box>
						<Typography variant="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
							{t('My work')}
						</Typography>
					</Box>
					<Box>
						<Typography variant="h4" sx={{ fontWeight: 500, color: 'text.secondary' }}>
							🌙 {t('Clock in to view your work')}
						</Typography>
					</Box>
					<Box sx={{ py: 3 }}>
						<Button fullWidth size="large" variant="contained" color="primary" onClick={() => navigate('/timeclock')}>
							{t('Go to Time Clock')}
						</Button>
					</Box>
				</Box>
			</Container>
		);
	}

	return (
		<Container maxWidth="md">
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '0.35rem' }}>
				<Typography variant="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
					{t('My work')}
				</Typography>
				<Box sx={{ alignSelf: 'baseline' }}>
					<IconButton onClick={() => navigate("/newline")} sx={{ p: 0 }}>
						<Avatar sx={{ backgroundColor: 'primary.main' }}>
							<AddIcon sx={{ color: 'white' }} />
						</Avatar>
					</IconButton>
				</Box>
			</Box>
			<Box component="form">
				<TextField
					fullWidth
					label={`${t('Filter list')}${
						workLinesFiltered.length !== data.length
							? ` (${workLinesFiltered.length} ${t("of")} ${data.length} ${t("shown")})`
							: ''
					}`}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					size="small"
					variant="outlined"
					inputProps={{
						style: { backgroundColor: 'white' },
					}}
				/>
			</Box>
			<Box mt={2}>
				<Typography variant="h6" display="block" gutterBottom sx={{ color: 'text.secondary'}}>
					{t('Last updated')}: {everyTenSeconds} {t('sec')}.
				</Typography>
			</Box>
			<Box mt={2}>
				{isLoading ? (
					<Skeleton height={500} />
				) : (
					<WorklineList worklines={workLinesFiltered} />
				)}
			</Box>
		</Container>
	);
};

export default WorkPage;
