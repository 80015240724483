import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const LoadingButton = ({ children, loading, ...props }) => {
	return (
		<Button {...props} disabled={loading || props?.disabled}>
			{loading ? (
				<>
					<CircularProgress
						size={24}
						color="inherit"
						style={{ marginRight: '8px' }}
					/>
					{children}
				</>
			) : (
				children
			)}
		</Button>
	);
};

export default LoadingButton;
