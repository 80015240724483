import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';


const BottomNav = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const [tabIndex, setTabIndex] = useState(getTabValue(location.pathname));

	useEffect(() => {
		setTabIndex(getTabValue(location.pathname));
	}, [location.pathname]);

	function getTabValue(path) {
		if (path === '/timeclock') {
			return 0;
		} else if (path === '/work' || path.startsWith('/work/') || path === '/newline') {
			return 1;
		} else {
			return 0;
		}
	}

	return (
		<Paper
			sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
			elevation={3}
		>
			<BottomNavigation
				showLabels
				value={tabIndex}
				onChange={(_, newValue) => {
					setTabIndex(newValue);
				}}
			>
				<BottomNavigationAction
					label={t('Time Clock')}
					component={Link}
					to="/timeclock"
					icon={<ClockIcon />}
				/>
				<BottomNavigationAction
					label={t('Work')}
					component={Link}
					to="/work"
					icon={<HomeIcon />}
				/>
			</BottomNavigation>
		</Paper>
	);
};

export default BottomNav;
