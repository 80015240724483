import React, { Suspense } from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';
import { store } from './App/store';
import './styles/styles.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<Suspense fallback="...is loading">
			<App />
		</Suspense>
		<ToastContainer
			autoClose={3500}
			position="top-center"
			hideProgressBar
			closeOnClick
		/>
	</Provider>,
);

serviceWorkerRegistration.unregister();
reportWebVitals();
