import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: {
					"odometerDialog": {
						"title": {
							km: "Vehicle Odometer",
							hours: "Equipment Usage Hours",
						},
						"inputLabel": {
							km: 'Odometer',
							hours: 'Usage hours',
						},
						"inputPlaceholder": {
							"km": "Type the current odometer reading",
							"hours": "Type the current hour meter reading"
						},
						"lastKnown": {
							"km": "Previous odometer reading:",
							"hours": "Previous hour usage:"
						},
						looksgood: 'Looks good!',
						"error": "Odometer must be more than",
						"validation": 'Are you sure you have the correct number?',
						"cancel": "Cancel",
						"save": "Save",
					},
					'Comment added': 'Comment added to time entry!',
					'Comment added error': 'Oops! Unable to save comment. Check your internet connection.',
					Yesterday: 'Yesterday',
					Today: 'Today',
					Past7TimeRecords: 'Time Entries for the last 7 days',
					Work: 'Work',
					'Time Clock': 'Time Clock',
					'Time entry': 'Time entry',
					Equipment: 'Equipment',
					Start: 'Start',
					End: 'End',
					'Clock in': 'Clock in',
					'Clock out': 'Clock out',
					hours: 'Hours',
					km: 'Km',
					'Clocked in': 'Clocked in',
					'Clocked out': 'Clocked out',
					'Waiting Approval': 'Waiting Approval',
					Approved: 'Approved',
					Rejected: 'Rejected',
					'No time entries. Clock in to start tracking your time.':
						'No time entries. Clock in to start tracking your time.',
					'Clock in to view your work': 'Clock in to view your work',
					'Sign out': 'Log out',
					'My work': 'My work',
					'Go to Time Clock': 'Go to Time Clock',
					'Filter list': 'Filter list',
					'Last updated': 'Last updated',
					sec: 'sec',
					of: "of",
					shown: "shown",

					'Report Absent': 'Report Absent',
					'Sick leave': 'Sick',
					'Sick leave with child': 'Sick with child',
					comment: 'Comment',
					Vacation: 'Vacation',
					Other: 'Other',
					Cancel: 'Cancel',
					Confirm: 'Confirm',
					Reason: 'Reason',

					'Back to time clock': 'Back to time clock',
					Home: 'Home',
					Welcome: 'Welcome',
					Trips: 'Trips',
					DayEnded: 'Thanks for the day',
					DayStarted: 'Clocked in and ready',
					DayStartedError: 'Failed to create workline',
					home: {
						workTitle: 'Work',
						instruction:
							"Clock in to view today's work and select your vehicle.",
						startWorkday: 'Start Workday',
						todaysWork: "Today's Work",

						goodday: 'Good day ',
						equip: 'Equipment',
						loading: 'Loading data',
						worktoday: 'Today´s worklines',
						logout: 'Log out',
						loggedin: 'Logged in',
						startday: 'Clock in',
						closetoday: 'Clock out',
						missingkm:
							'You must put in KM / hours odometer before starting work',
						congrats: 'Happy birthday ',
					},
					work: {
						mylines: 'My work',
						from: 'From: ',
						location: 'Location: ',
						to: 'To: ',
						type: 'Type: ',
						product: 'Product: ',
						count: 'Count: ',
						equip: 'Equipment: ',
						yourequip: 'Your equipment: ',
						description: 'Description',
						workstarted: 'Work started ',
						workended: 'Work ended ',
						trip: 'Trip',
						empnote: 'Employee´s note',
						savenote: 'Save comment',
						extra: 'Extra equipment',
						start: 'Start work',
						close: 'Stop work',
						extracreate: 'Add to line',
					},
					newline: {
						project: 'Project',
						ttype: 'Type',
						product: 'Product',
						location: 'Worksite',
						to: 'To',
						from: 'From',
						equip: 'Equipment',
						create: 'Create work line',
					},
					"noPrivileges": {
						"title": "Missing App Privileges",
						"message": "Please contact your administrator for assistance.",
						"signOut": "Sign Out"
					},
					payPeriodSummary: {
						TimeTotalLast2Months: "Time summary last 2 months",
						Day: "Day job",
						Overtime: "Overtime",
						Vacation: "Vacation",
						Abscence: "Abscence",
						Sick: "Sick",
						SickChild: "Child Sick Leave"
					},
				},
			},
			is: {
				translation: {
					"odometerDialog": {
						"title": {
							km: "Kílómetrastaða tækis",
							hours: "Tímastaða tækis",
						},
						"inputLabel": {
							km: 'Kílómetramælir',
							hours: 'Tímastaða',
						},
						"inputPlaceholder": {
							"km": "Sláðu inn kílómetrastöðu",
							"hours": "Sláðu inn tímastöðu"
						},
						"lastKnown": {
							km: 'Síðasta kílometrastaða:',
							hours: 'Síðasta tímastaða:'
						},
						"error": "Staða verður að vera meiri en",
						"save": "Vista",
						"cancel": "Hætta við",
						looksgood: 'Lítur vel út!',
						validation: 'Ertu örugglega með rétta tölu?',
					},
					'Comment added': 'Athugasemd skráð!',
					'Comment added error': 'Obbosí! Ekki tókst að vista athugasemdina. Athugaðu nettenginguna þína.',
					Yesterday: 'Í gær',
					Today: 'Í dag',
					Past7TimeRecords: 'Skráningar seinustu 7 daga',
					Work: 'Vinna',
					'Time Clock': 'Tímaskráning',
					'Time entry': 'Tímaskráning',
					Equipment: 'Tæki',
					'Clock in': 'Stimpla inn',
					'Clock out': 'Stimpla út',
					'Odometer (km)': 'Staða kílómetramælis',
					'Odometer (hours)': 'Tímastaða tækis',
					Start: 'Inn',
					End: 'Út',
					hours: 'Klst',
					km: 'Km',
					'Clocked in': 'Stimplað inn',
					'Clocked out': 'Stimplað út',
					'Waiting Approval': 'Bíður samþykktar',
					Approved: 'Samþykkt',
					Rejected: 'Ekki samþykkt',
					'No time entries. Clock in to start tracking your time.':
						'Engar tímaskráningar. Stimplaðu þig inn til að sjá nýjustu færslur.',
					'Clock in to view your work':
						'Stimplaðu þig inn til að til að sjá verk dagsins',
					'Sign out': 'Útskráning',
					'My work': 'Mínar verklínur',
					'Go to Time Clock': 'Tímaskráning',
					'Filter list': 'Sía lista',
					'Last updated': 'Síðast uppfært',
					sec: 'sek',
					of: "af",
					shown: "sýndir",

					'Report Absent': 'Skrá fjarveru',
					'Sick leave': 'Veikindi',
					'Sick leave with child': 'Veikindi barns',
					comment: 'Athugasemd',
					Vacation: 'Frí',
					Other: 'Annað',
					Cancel: 'Hætta við',
					Confirm: 'Staðfesta',
					Reason: 'Ástæða',

					'Back to time clock': 'Tilbaka í tímaskráningu',
					Home: 'Heim',
					Welcome: 'Velkomin',
					Trips: 'Ferðir',
					DayEnded: 'Takk fyrir daginn',
					DayStarted: 'Skráður inn og tilbúinn í daginn',
					DayStartedError: 'Ekki tókst að stofna verklínu',
					home: {
						workTitle: 'Verklínur',
						instruction: 'Stimplaðu þig inn og veldu tæki til að sjá verk dagsins.',
						startWorkday: 'Hefja dag',
						todaysWork: 'Verklínur dagsins',
						goodday: 'Góðan dag ',
						equip: 'Tæki',
						loading: 'Hleð gögn',
						worktoday: 'Verklínur dagsins',
						logout: 'Útskráning',
						loggedin: 'Innskráður',
						startday: 'Hefja dag',
						closetoday: 'Ljúka degi',
						missingkm: 'Nauðsynlegt er að slá inn kílómetra/tímastöðu til að halda áfram.',
						congrats: 'Til hamingju með afmælið ',
					},
					work: {
						mylines: 'Mínar verklínur',
						from: 'Frá: ',
						location: 'Staðsetning: ',
						to: 'Til: ',
						type: 'Tegund: ',
						product: 'Vara: ',
						count: 'Fjöldi: ',
						equip: 'Tæki: ',
						yourequip: 'Þitt tæki: ',
						description: 'Lýsing',
						workstarted: 'Vinna hófst ',
						workended: 'Vinna endaði ',
						trip: 'Ferð',
						empnote: 'Athugasemd starfsmanns',
						savenote: 'Vista athugasemd',
						extra: 'Aukabúnaður',
						start: 'Hefja verk',
						close: 'Loka verki',
						extracreate: 'Bæta við',
					},
					newline: {
						project: 'Verkefni',
						ttype: 'Tegund',
						product: 'Vara',
						location: 'Verkstaður',
						to: 'Til',
						from: 'Frá',
						equip: 'Tæki',
						create: 'Stofna verk',
					},
					"noPrivileges": {
						"title": "Þig vantar app leyfi",
						"message": "Hafðu samband til að fá aðstoð.",
						"signOut": "Skrá út"
					},
					payPeriodSummary: {
						TimeTotalLast2Months: "Tímasamtala síðustu 2 mánuði",
						Day: "DV",
						Overtime: "YV",
						Vacation: "Frí",
						Abscence: "Fjarvera",
						Sick: "Veikindi",
						SickChild: "Veikindi barna"
					},
				},
			},
		},
	});

export default i18n;
