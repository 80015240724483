import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
	Container,
	Button,
	Box,
	Typography,
	Skeleton,
	Grid,
} from '@mui/material';

import {
	LoadingButton,
	TimeRecordList,
	CurrentTime,
	ReportAbsentDialog,
	TimeRecordDialog,
	PayPeriodSummaryTable,
	DriverOdometerDialog
} from '../../components';
import {
	useClockInMutation,
	useCreateWorklineMutation,
	useEndWorklineMutation,
	useClockOutMutation,
	useGetTimeRecordsQuery,
	useGetWorklinesQuery,
	useGetLatestWorklineQuery
} from '../../services/api';
import { employeeActions } from '../../App/employeeSlice';

const ClockInButton = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { user, hasVerklinur, hasDriver, isOdometerDialogOpen, defaultEquipment } = useSelector((state) => state.employee);
	const [clockIn, { isLoading: isClockingIn }] = useClockInMutation();
	const [createWorkline, { isLoading: isCreatingWorkline }] = useCreateWorklineMutation();

	const { data: workLines = [] } = useGetWorklinesQuery(user.id, {
		skip: !user.id || !hasVerklinur,
	});

	const handleOpenOdometerDialog = () => dispatch(employeeActions.openOdometerDialog());

	const handleClockIn = () => {
		const timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		dispatch(employeeActions.setTimeClockIsLoading(true));
		// Clock in the employee
		clockIn({
			employeeId: user.id,
			timestamp: timestamp,
		})
		.unwrap()
		.then(() => {
			// Mark employee clocked in
			dispatch(employeeActions.setTimeClockedIn(timestamp));
			toast.success(
				`${t('Clocked in')}! ${dayjs(timestamp).format(
					'ddd DD MMM HH:mm',
				)}`,
			);

			// Navigate to /work if has active work lines otherwise to /newline
			const hasActiveWorkLines =
				Array.isArray(workLines) &&
				workLines.length > 0 &&
				workLines.some((line) => line?.isFinished === false);

			if (hasActiveWorkLines) navigate('/work');
			else navigate('/newline');
		})
		.catch((error) => {
			toast.error(`Innstimplun tókst ekki`);
		})
		.finally(() => {
			dispatch(employeeActions.setTimeClockIsLoading(false));
        });
	};

	const handleCreateWorkline = async (odometer) => {
		try {
			await createWorkline({
				EId: user.id,
				PId: "43c3a273-21c3-ec11-a7b6-000d3ade21b2", // Tímaskráning á sandbox og raun
				EqId: defaultEquipment.id,
				KMin: `${odometer}`,
				WTId: "22241f3d-90b0-ec11-9840-000d3abf79e7", // Tímaskráning á sandbox og raun
				WSId: "",
				FromId: "",
				ToId: "",
				ProId: "",
				Location: {
					Latitude: 0, 
					Longitude: 0, 
				},
			}).unwrap();
			handleClockIn();
		} catch (error) {
			toast.error(`Innstimplun tókst ekki`);
		}
	};

	return (
		<>
			<LoadingButton
				size="large"
				variant="contained"
				fullWidth
				onClick={hasDriver ? handleOpenOdometerDialog : handleClockIn}
				loading={isClockingIn || isCreatingWorkline}
				disabled={isClockingIn || isCreatingWorkline}
			>
				{t('Clock in')}
			</LoadingButton>
			<DriverOdometerDialog
				open={isOdometerDialogOpen}
				onClose={() => dispatch(employeeActions.closeOdometerDialog())}
				onConfirm={(odometer) => {
					dispatch(employeeActions.closeOdometerDialog());
					handleCreateWorkline(odometer);
				}}
			/>
		</>
	);
};

const ClockOutButton = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { user, hasDriver, isOdometerDialogOpen, timeClock: { timeRecordId } } = useSelector(
		(state) => state.employee
	);
	const [clockOut, { isLoading: isClockingOut }] = useClockOutMutation();
	const [endWorkline, { isLoading: isEndingWorkline }] = useEndWorklineMutation();
	const { data: latestWorkline } = useGetLatestWorklineQuery(user.id, {
		skip: !user.id || !hasDriver,
	});

	const handleOpenOdometerDialog = () => dispatch(employeeActions.openOdometerDialog());
	const handleClockOut = () => {
		const timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		// Clock out the employee
		clockOut({
			timeRecordId,
			employeeId: user.id,
			timestamp: timestamp,
		})
		.unwrap()
		.then(() => {
			// Mark employee clocked out
			dispatch(employeeActions.setTimeClockedOut(timestamp));
			toast.success(
				`${t('Clocked out')}! ${dayjs(timestamp).format(
					'ddd DD MMM HH:mm',
				)}`,
			);
		})
		.catch((error) => {
			toast.error(`Ekki tókst að ljúka deginum`);
		});
	};

	const handleEndWorkline = async (odometer) => {
		try {
			if (latestWorkline) {
				await endWorkline({
					EId: user.id,
					EqId: "", 
					WId: latestWorkline?.id || "",
					KMOut: `${odometer}`,
					Location: {
						Latitude: 0,
						Longitude: 0,
					},
				}).unwrap();
			}

			handleClockOut(); // clock out after ending the workline
		} catch (error) {
			toast.error(`Ekki tókst að ljúka deginum`);
		}
	};

	return (
		<>
			<LoadingButton
				size="large"
				color="error"
				variant="contained"
				fullWidth
				onClick={hasDriver ? handleOpenOdometerDialog : handleClockOut}
				loading={isClockingOut || isEndingWorkline}
				disabled={isClockingOut || isEndingWorkline}
			>
				{t('Clock out')}
			</LoadingButton>
			<DriverOdometerDialog
				open={isOdometerDialogOpen}
				onClose={() => dispatch(employeeActions.closeOdometerDialog())}
				onConfirm={(odometer) => {
					dispatch(employeeActions.closeOdometerDialog());
					handleEndWorkline(odometer);
				}}
			/>
		</>
	);
};

const ReportAbsentButton = ({ disabled }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<>
			<Button
				size="large"
				variant="contained"
				color="secondary"
				onClick={() => setOpen(true)}
				disabled={disabled}
			>
				{t('Report Absent')}
			</Button>
			<ReportAbsentDialog open={open} setOpen={setOpen} />
		</>
	);
};

const TimeClockPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { user, timeClock, isTimeRecordDialogOpen, selectedTimeRecordId } = useSelector((state) => state.employee);
	const {
		isLoading,
		isActive,
		timeClockedIn,
		timeClockedOut,
		durationMinutes,
	} = timeClock;

	const { data: timeRecords, isFetching: isLoadingTimeRecords } = useGetTimeRecordsQuery({
		employeeId: user.id,
		startDate: dayjs().subtract(7, 'day').toISOString(),
		endDate: dayjs().toISOString()
	}, { skip: !user.id });

	const timeRecord = timeRecords?.find((record) => record.id === selectedTimeRecordId);

	return (
		<Container maxWidth="md">
			<Grid container direction="column" spacing={2}>
				<Grid item xs={8}>
					<Typography
						variant="h1"
						gutterBottom
						sx={{ fontWeight: 'bold' }}
					>
						{t('Time Clock')}
					</Typography>
					<Box>
						<CurrentTime />
						{isLoading ? (
							<Skeleton
								variant="text"
								sx={{ fontSize: '1rem' }}
							/>
						) : (
							<Box
								sx={{
									display: 'flex',
									gap: 1,
									color: 'text.secondary',
									alignItems: 'center',
								}}
							>
								{isActive ? (
									<>
										<Typography variant="h6">
											🟢{`${t('Clocked in')}:`}
										</Typography>
										<Typography
											variant="h6"
											sx={{ textTransform: 'capitalize' }}
										>
											{timeClockedIn
												? dayjs(timeClockedIn).format(
														'ddd DD MMM HH:mm',
												  )
												: ''}
										</Typography>
									</>
								) : (
									<>
										<Typography variant="h6">
											🔴{`${t('Clocked out')}:`}
										</Typography>
										<Typography
											variant="h6"
											sx={{ textTransform: 'capitalize' }}
										>
											{timeClockedOut
												? dayjs(timeClockedOut).format(
														'ddd DD MMM HH:mm',
												  )
												: ''}
										</Typography>
										{durationMinutes && (
											<Typography variant="h6">
												{dayjs
													.duration(
														durationMinutes,
														'minutes',
													)
													.asHours()
													.toFixed(2)}{' '}
												{t('hours').toLowerCase()}
											</Typography>
										)}
									</>
								)}
							</Box>
						)}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 1,
								py: 4,
							}}
						>
							{isLoading ? (
								<>
									{/* Skeleton for ClockIn/ClockOut Button */}
									<Skeleton variant="rectangular" width='100%' height={40} />
									{/* Skeleton for ReportAbsentButton */}
									<Skeleton variant="rectangular" width='100%' height={40} />
								</>
							) : (
								<>
									{!isActive ? <ClockInButton /> : <ClockOutButton />}
									<ReportAbsentButton disabled={isActive || isLoading} />
								</>
							)}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={1}>
					<Typography variant="h4" gutterBottom>
						{t('Past7TimeRecords')}
					</Typography>
					{isLoadingTimeRecords ? (
						<Skeleton variant="rectangular" height={400} />
					) : (
						<TimeRecordList timeRecords={timeRecords || isLoading} />
					)}
				</Grid>
				<Grid item xs={1}>
					<Typography variant="h4" gutterBottom>
						{t('payPeriodSummary.TimeTotalLast2Months')}
					</Typography>
					<PayPeriodSummaryTable />
				</Grid>
			</Grid>
			<TimeRecordDialog
				timeRecord={timeRecord}
				isOpen={isTimeRecordDialogOpen}
				onClose={() => dispatch(employeeActions.closeTimeRecordDialog())}
			/>
		</Container>
	);
};

export default TimeClockPage;
