import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({ baseUrl: 'api/', credentials: 'include' }),
	refetchOnReconnect: true,
	tagTypes: ['ActiveTimeRecord', 'Equipment', 'TimeRecords'],
	endpoints: (builder) => ({
		// Time Clock
		getTimeRecords: builder.query({
			query: ({ employeeId, startDate, endDate }) => {
				let url = `time-records/employees/${employeeId}`;
				const params = new URLSearchParams();
				if (startDate) {
					params.append('startDate', startDate);
				}
				if (endDate) {
					params.append('endDate', endDate);
				}
				if (params.toString()) {
					url += `?${params.toString()}`;
				}
				return url;
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName
			},
			providesTags: ['TimeRecords'],
		}),
		getClockInStatus: builder.query({
			query: (employeeId) =>
				`time-records/employees/${employeeId}/latest`,
			providesTags: ['ActiveTimeRecord'],
		}),
		clockIn: builder.mutation({
			query: ({
				employeeId,
				equipmentId,
				odometer,
				timestamp,
			} = {}) => ({
				url: 'time-records',
				method: 'POST',
				body: {
					EmployeeId: employeeId,
					EquipmentId: equipmentId,
					Odometer: odometer,
					Timestamp: timestamp,
				},
			}),
			invalidatesTags: ['TimeRecords', 'ActiveTimeRecord', 'PayPeriodSummary', 'DefaultEquipment'],
		}),
		reportAbsent: builder.mutation({
			query: ({
				employeeId,
				timestamp,
				timestampOut,
				reason,
			} = {}) => ({
				url: 'time-records',
				method: 'POST',
				body: {
					EmployeeId: employeeId,
					Timestamp: timestamp,
					TimestampOut: timestampOut,
					Reason: reason,
				},
			}),
			invalidatesTags: ['TimeRecords', 'PayPeriodSummary'],
		}),
		clockOut: builder.mutation({
			query: ({ timeRecordId, employeeId, timestamp, message } = {}) => ({
				url: `time-records/${timeRecordId}`,
				method: 'PATCH',
				body: {
					EmployeeId: employeeId,
					Timestamp: timestamp,
					Message: message,
				},
				validateStatus: (response, result) => response.status === 204,
			}),
			invalidatesTags: ['TimeRecords', 'ActiveTimeRecord', 'PayPeriodSummary', 'DefaultEquipment'],
		}),
		updateComment: builder.mutation({
			query: ({ id, comment } = {}) => ({
				url: `time-records/${id}/comment`,
				method: 'PATCH',
				body: {
					Comment: comment,
				},
				validateStatus: (response, _) => response.status === 204,
			}),
			invalidatesTags: (_, error) => error ? [] : ['TimeRecords', 'ActiveTimeRecord'],
		}),
		getPayPeriodSummary: builder.query({
			query: (employeeId) => `employee/PayPeriodSummary/${employeeId}`,
			providesTags: ['PayPeriodSummary'],
		}),

		// Equipment
		getAllEquipment: builder.query({
			query: () => `employee/GetAvailableEquipment`,
			providesTags: ['Equipment'],
		}),
		getWorklines: builder.query({
			query: (employeeId) => `work/worklines/${employeeId}`,
		}),
		getLatestWorkline: builder.query({
			query: (id) => `employee/CheckIfEmployeeActiveToday/${id}`,
		}),
		getDefaultEquipment: builder.query({
			query: (employeeId) => `employee/GetDefaultEquipment/${employeeId}`,
			providesTags: ['DefaultEquipment'],
		}),

		// Worklines
		createWorkline: builder.mutation({
			query: (payload) => ({
				url: 'work/createworkline',
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: ['DefaultEquipment'],
		}),
		endWorkline: builder.mutation({
			query: (payload) => ({
				url: `work/endworkline`,
				method: 'POST',
				body: payload,
			}),
			invalidatesTags: ['DefaultEquipment'],
		}),
		getOpenProjects: builder.query({
			query: (employeeId) => `work/GetAllOpenProjects/${employeeId}`,
			providesTags: ['OpenProjects'],
		}),
		getProjectWorklineTypes: builder.query({
			query: (projectId) => `work/GetProjectWorkLineTypes/${projectId}`,
			providesTags: ['ProjectWorklineTypes'],
		}),		
		getAvailableProducts: builder.query({
			query: (employeeId) => `work/GetAllAvailableProducts/${employeeId}`,
			providesTags: ['Products'],
		}),
		getAvailableWorksites: builder.query({
			query: (employeeId) => `work/GetAllAvailableWorksites/${employeeId}`,
			providesTags: ['Worksites'],
		}),
		getWorklineTypes: builder.query({
			query: (employeeId) => `work/GetAllWorkLineTypes/${employeeId}`,
			providesTags: ['WorklineTypes'],
		}),
	}),
});

export const {
	useReportAbsentMutation,
	useGetTimeRecordsQuery,
	useGetClockInStatusQuery,
	useClockInMutation,
	useClockOutMutation,
	useGetDefaultEquipmentQuery,
	useGetAllEquipmentQuery,
	useGetLatestWorklineQuery,
	useCreateWorklineMutation,
	useEndWorklineMutation,
	useGetWorklinesQuery,
	useGetOpenProjectsQuery,
	useGetProjectWorklineTypesQuery,
	useGetAvailableProductsQuery,
	useGetAvailableWorksitesQuery,
	useGetWorklineTypesQuery,
	useUpdateCommentMutation,
	useGetPayPeriodSummaryQuery
} = api;
