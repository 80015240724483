//grafadev : client = ddd9ccd0-a2c4-47e9-beb2-9ebf5737362d
//grafaprod : client = 8a561920-844e-48af-b507-ddedf08441f5
export const msalConfig = {
	authority:
		'https://login.microsoftonline.com/a477de4f-c51e-4a57-94f5-5c9e63cb4e3c',
	clientId:
		window.location.href.indexOf('grafadockerdev') > -1 ||
		window.location.href.indexOf('grafadev') > -1 ||
		window.location.href.indexOf('localhost') > -1
			? 'ddd9ccd0-a2c4-47e9-beb2-9ebf5737362d'
			: '8a561920-844e-48af-b507-ddedf08441f5',
	redirectUri: window.location.origin,
};
