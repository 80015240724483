import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Box, Typography, Divider, Chip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import { employeeActions } from '../../App/employeeSlice';
import { getStatusLabel, getTypeLabel, getTimeRangeWithDuration, getStatusColor } from '../../utils/timerecord.utils';

const TimeRecordList = ({ timeRecords }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	if (!timeRecords || timeRecords.length === 0) {
		return (
			<Box sx={{ p: 2, textAlign: 'center', mt: 3 }}>
				<Typography>
					{t(
						'No time entries. Clock in to start tracking your time.',
					)}
				</Typography>
			</Box>
		);
	}

	const handleOnClick = (timeRecord) => {
		dispatch(employeeActions.openTimeRecordDialog(timeRecord.id));
	};

	return (
		<List sx={{ width: '100%', overflowY: 'auto' }}>
		{timeRecords &&
			timeRecords.map((record, i) => (
				<Box key={record.id}>
					<ListItem
						sx={{ pl: 0 }}
						secondaryAction={
							<IconButton edge="end" aria-label="comment" onClick={() => { handleOnClick(record) }}>
								<EditIcon />
							</IconButton>
						}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography
									gutterBottom
									sx={{
										textTransform: 'capitalize',
										fontWeight: 'medium',
									}}
								>
									{record?.start
										? dayjs(record.start).format(
												'ddd DD MMM',
											)
										: ''}
								</Typography>
								<Box>
									{record?.type && (
										<Chip
											label={getTypeLabel(record)}
											size="small"
										/>
									)}
								</Box>
							</Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant="body1">
									{getTimeRangeWithDuration(record)}
								</Typography>
								<Box>
									{record?.status && (
										<Chip
											label={getStatusLabel(record.status)}
											color={getStatusColor(record.status)}
											size="small"
											variant="outlined"
										/>
									)}
								</Box>
							</Box>
						</Box>
					</ListItem>
					{i !== timeRecords.length - 1 && <Divider variant="inset" component="li" />}
				</Box>
			))}
		</List>
	);
};

export default TimeRecordList;
